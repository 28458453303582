<div class="modal-main-container">
  <img class="img-content" [src]="imageUrl" />
  <p class="content">
    {{ headingContent | translate }}
    <span class="text-xs flex">{{ factoryContent ? factoryContent : "" }}</span>
    @if(isRoleAccess){
    <span>{{ contentName }} {{ "EMPLOYEE" | translate }} ? </span>
    } @else if(isEmployee){
    <span> {{ contentName }} </span>
    }
    <span>{{ codeContent }}?</span>
  </p>
  <div class="flex justify-between mt-1 w-full gap-4">
    <app-button
      [name]="'CANCEL'"
      (onClick)="onCancel()"
      class="w-full"
      [btnClass]="'cancel-btn'"
    ></app-button>
    <app-button
      [name]="'YES'"
      class="w-full"
      (onClick)="onConfirm()"
      [btnClass]="'save-btn'"
    ></app-button>
  </div>
</div>
